import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const MainContactContainer = styled.div`
  padding-top: 100px;
  min-height: 802px;
  width: 100%;
  background-color: ${themeGet("colors.primary")};
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  padding: 1.5rem 0.8rem;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

  & .text-content {
    width: 50%;
    @media only screen and (max-width: 1000px) {
      width: 80%;
      text-align: center;
    }
    @media only screen and (max-width: 530px) {
      width: 95%;
    }

    & .form-img {
      height: 200px;
      width: 200px;

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
  }

  & .form {
    width: 40%;
    background-color: rgba(240, 248, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    @media only screen and (max-width: 1000px) {
      width: 80%;
    }

    @media only screen and (max-width: 530px) {
      width: 95%;
    }

    & .contact-input {
      input,
      textarea {
        transition: all 0.4s ease;
        &:focus {
          outline: none;
          border-width: 2px;
          border-color: #e2ad20;
        }
      }
    }

    & .form-btn {
      border-radius: 10px;
      width: 30%;

      &:hover {
        background-color: #344370;
      }
    }
  }
`;

export const CustomPara = styled.p`
  font-family: "Heebo", sans-serif;
  font-weight: normal;
  margin: 0 0 20px 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  text-justify: auto;
  text-align: left;

  @media only screen and (max-width: 1000px) {
    text-align: center;
  }
`;

export const InputContainer = styled.div`
  text-align: left;
  margin-bottom: 1.5rem;
`;

export const InputTitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 0.9rem;
  margin-bottom: 10px;
`;

export default MainContactContainer;
