import { Modal } from "@redq/reuse-modal";
import { ResetCSS } from "common/assets/css/style";
import Heading from "common/components/Heading";
import { theme } from "common/theme/appModern";
import Seo from "components/seo";
import Navbar from "containers/AppModern/Navbar";
import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import Fade from "react-reveal/Fade";
import SendData from "../../../common/assets/image/send-data.png";
import Button from "../../../common/components/Button";
import Input from "../../../common/components/Input";
import Footer from "../Footer";
import GlobalStyle, { AppWrapper } from "../appModern.style";
import MainContactContainer, {
  ContentWrapper,
  CustomPara,
  InputContainer,
} from "./contactus.styles";
import companyInfo from "../About-us/data";

const ContactUs = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <>
          <Seo title="Comrex Pvt Ltd: copyright" />
          <Modal />
          <ResetCSS />
          <GlobalStyle />
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>

            <MainContactContainer>
              <ContentWrapper>
                <Fade left distance="200px">
                  <div className="text-content">
                    <img
                      src={SendData}
                      className="form-img"
                      alt="Message logo"
                    />
                    <Heading
                      as="h1"
                      content="We’d Love to Hear from You"
                      color="white"
                    />
                    <CustomPara>
                      Whether you have a question about our services, need a
                      consultation, or want to start a new project, our team is
                      here to help. Reach out to us, and we’ll respond as soon
                      as possible to discuss how we can bring your ideas to life
                    </CustomPara>
                    <Fade up delay={100}>
                      <Heading
                        as="h2"
                        color="white"
                        content={companyInfo.contact.heading}
                      />
                      <CustomPara>
                        {companyInfo.contact.administration.name}
                      </CustomPara>
                      <CustomPara>
                        {companyInfo.contact.administration.address}
                      </CustomPara>
                      <CustomPara>
                        {companyInfo.contact.administration.email}
                      </CustomPara>
                      <CustomPara>
                        {companyInfo.contact.administration.phone}
                      </CustomPara>
                    </Fade>
                  </div>
                </Fade>

                <Fade right distance="200px">
                  <form
                    className="form"
                    action="mailto:mshamsuddin448@gmail.com"
                    method="GET"
                  >
                    <InputContainer>
                      <Input
                        placeholder="John Doe"
                        label="Name: "
                        required
                        type="text"
                        className="contact-input"
                      />
                    </InputContainer>

                    <InputContainer>
                      <Input
                        placeholder="example@abc.com"
                        label="Email: "
                        type="text"
                        required
                        className="contact-input"
                      />
                    </InputContainer>

                    <InputContainer>
                      <Input
                        placeholder="+92 123 4567"
                        label="Phone: "
                        type="phone"
                        required
                        className="contact-input"
                      />
                    </InputContainer>

                    <InputContainer>
                      <Input
                        placeholder="e.g Pakistan"
                        label="Country: "
                        required
                        type="text"
                        className="contact-input"
                      />
                    </InputContainer>

                    <InputContainer>
                      <Input
                        placeholder="Type here..."
                        label="Your Message: "
                        inputType="textarea"
                        className="contact-input"
                      />
                    </InputContainer>

                    <Button type="submit" title="Send" className="form-btn" />
                  </form>
                </Fade>
              </ContentWrapper>
            </MainContactContainer>
            <Footer />
          </AppWrapper>
        </>
      </ThemeProvider>
    </div>
  );
};

export default ContactUs;
